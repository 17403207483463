import { render, staticRenderFns } from "./StockOrdersListing.vue?vue&type=template&id=83a1e98e&"
import script from "./StockOrdersListing.vue?vue&type=script&lang=js&"
export * from "./StockOrdersListing.vue?vue&type=script&lang=js&"
import style0 from "./StockOrdersListing.vue?vue&type=style&index=0&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports